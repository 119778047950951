<template lang="pug">
	div.page-statistics
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="filters")
			.widget-header
				h1.title Rejected Installs
			.widget-body
				form.form.form-inline.ov-filters(@submit.prevent="loadData()")
					a.toggle-filters(href="javascript: void(0);", @click="toggleFilters()")
						span Filters
						i.la.la-chevron-up
					.form-row-main
						ov-date-time-picker(v-model="filters.datePicker")
						.form-group.no-label
							button.btn.btn-primary(type="submit") Go
								//b-button-group
									b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
										b-dropdown-item(@click="loadData(true)")
											i.la.la-download
											| Export
				p.clearfix &nbsp;

				paginate(:paginator="paginate", @update-page-size="loadData")

				.ov-table-wrapper
					table.table.table-bordered.table-condensed
						thead
							tr
								th ID
								th OfferID
								th OfferName
								th Sub1
								th Sub2
								th ClickID
								th Click Time
								th Reject Reason
						tbody
							tr(v-if="records.length===0")
								td(colspan="9") No matching records were found
							tr(v-for="r in records", v-bind:key="r.id")
								td {{ r.id }}
								td {{ r.offer_id }}
								td {{ r.offer_name }}
								td {{ r.sub1 }}
								td {{ r.sub2 }}}
								td
									div.aff-clickid {{ r.aff_clickid }}
								td {{ r.click_time }}
								td {{ r.reject_reason }}
				paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.aff-clickid {
	max-width: 300px;
	word-break: break-all;
}
</style>
<script>
import moment from 'moment';

export default {
	name: 'Events',
	computed: {
		USER(){
			return this.$store.state.user;
		},
	},
	data() {
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 30,
				onPageChange: () => {
					this.loadData();
				}
			},
			records: [],
			filters: {
				offer_id: [],
				datePicker: {
					timezone: 0,
					dateRange: {
						startDate: null,
						endDate: null
					},
				}
			}
		};
	},
	methods: {
		async loadData(doExport){
			if (this.busy) {
				return;
			}
			let params = {
				export: doExport ? 1 : 0,
				page: this.paginate.page,
				page_size: this.paginate.limit,
				offer_id: this.filters.offer_id.map(r => r.id).join(','),
				timezone: this.filters.datePicker.timezone,
				date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
				date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
			};

			this.busy = true;
			try {
				let resp = await this.$ovReq.get('publishers/rejected/getList', {params});
				if (doExport){
					window.open(resp.path);
				} else {
					this.records = resp.records;
					this.paginate.total = resp.total;
					this.additionalColumns = resp.columns;
					this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
				}
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	},
	mounted(){
		this.loadData();
	}
}
</script>
